* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
  font-size: 100%;
}

:root {
	--orangecolor:#F7941D;
  --white: #ffffff;
	--black: #000000;
  --lightgrey:#F7F7F7;
}

body {
  width: 100vw;
  height: auto;
  font-size: 16px;
  color: #000000;
  background-color: #f3f3f3;
  font-family: Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

.icon2 {
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.icon2:hover {
  background-color: var(--secondary-dark);
  cursor: pointer;
}

.icon-back{
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--white);
}


/* ..........................................................Navbar........................................................... */

.navbar{
  height: 70px;
}

.navbar-custom {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  transition: background-color 0.3s ease, margin-top 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  margin: 0;
}

.navbar-brand img {
  height: 50px;
}

.navbar-nav {
  display: flex;
  justify-content: center;
}

.navbar-nav .nav-item {
  margin: 0 20px;
  height: 100%;
  padding: 20px;
  text-decoration: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
}

.navbar-nav .nav-link {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 0 15px;
  line-height: 50px; 
  height: 50px; 
}

.navbar-nav .nav-link:hover {
  color: #ddd;
  background-color: var(--orangecolor);
}

.navbar-nav .active-link {
  color: white;
  background-color: var(--orangecolor);
}

.form-inline {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler .fas {
  color: #fff;
}

.navbar-custom.scrolled {
  background-color: white;
  margin-top: 0;
}

.navbar-custom.scrolled .nav-item {
  color: black;
  font-size: 1rem;
  font-weight: 700;
}

.navbar-custom.scrolled .nav-link:hover {
  color: white;
  background-color: var(--orangecolor);
}

.navbar-custom.scrolled .active-link {
  color: white;
  background-color: var(--orangecolor);
}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
    flex-direction: column;
  }

  .form-inline {
    margin-top: 10px;
  }

  .navbar-nav .nav-link {
    line-height: normal; 
    height: auto; 
  }
}


/* ..........................................................Home Page........................................................... */

.home-container {
  display: flex;
  align-items: center;
  background-color:var(--lightgrey);
  margin-top: 100px;
}

.text-content {
  flex: 1;
  max-width: 50%;
  margin-right: 20px; 
  padding-left: 25%;
}

.discount {
  font-size: 1.3rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.main-heading {
  font-size: 3rem;
  font-weight: 700;
  color: var(--orangecolor);
  margin-bottom: 15px;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  width: 400px;
}

.shop-now {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color:#333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.shop-now:hover {
  background-color: darkorange;
}

.image-content {
  flex: 1;
}

.shirt-image {
  max-width: 100%;
  border-radius: 5px;
}




/* ..........................................................Home Carousel........................................................... */

.product-heading {
  text-align: center;
  font-size: 2rem; 
  color: #333;
  margin: 10px 0;
  height: 100%;
  overflow: hidden;
  padding-top: 60px;
}

.show-btn{
   justify-content: center;
   text-align: center;
   margin: 0px 0;
}

.product-carousel-container {
  padding: 0px;
  overflow: hidden;
  position: relative;
}

.product-carousel {
  overflow-x: scroll;
  overflow: hidden;
  scroll-behavior: smooth;
  gap: 60px; 
  width: 100%;
  margin-left: 0px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: none;
  font-size: 3rem;
  color: grey;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
  border-radius: 100%;
}

.product-left-arrow {
  left: 10px;
}

.product-right-arrow {
  right: 10px;
}

.product-carousel-subheading {
  color: var(--orangecolor);
}

.product-carousel-main-heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  width: 150px;
}

.product-carousel-shop-now{
  margin-top: 25px;
  margin-bottom: -15px;
}

.product-divider {
  border: 1px solid var(--black);
  margin-top: -10px;
  width: 80px;
}

.product-carousel-image {
  flex: 0 0 auto;
  right: 0;
  bottom: 0;
  z-index: 0;
  position: absolute;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.show-more-products{
  font-size: 15px;
  padding: 10px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.44); 
  background: rgba(128, 128, 128, 0.279);
  font-style: bold;
  font-weight: 600;
}

.show-more-products:hover{
  background-color: var(--orangecolor);
  color: white;
}

.wishlist-icon {
  position: absolute;
  cursor: pointer;
}

.wishlist-icon-image {
  width: 24px;
  height: 24px; 
}


.show-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ..........................................................Services........................................................... */

.services-section {
  padding: 15px 25px;
  background-color: #f2f2f2;
}

.services-heading {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-left: 100px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
}

.service-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.service-heading {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.service-description {
  font-size: 1rem;
  color: #666;
}

/* ..........................................................NewsLetter........................................................... */

.email-section {
  margin: 20px 0;
  text-align: center;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
  width: fit-content;
}

.email-input {
  padding: 15px 20px;
  width: 500px; 
  border: none;
  outline: none;
  font-size: 16px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.send-email{
  padding: 15px 25px;
  border: none;
  background-color: var(--orangecolor); 
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: background-color 0.3s ease-in-out;
}

.send-email:hover {
  background-color: var(--black); 
}


/* ..........................................................Blogs........................................................... */

.blog-section {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-head {
  position: relative;
  padding-top: 60px;
  margin-bottom: 20px; 
  padding-bottom: 10px;
}

.blog-head::before {
  content: "";
  position: absolute;
  left: 48%;
  bottom: 0; 
  width: 60px;
  height: 4px; 
  background-color: orange; 
}

.blog-item {
  position: relative;
  flex: 1 1 300px;
  max-width: 300px;
  margin: 10px;
  overflow: hidden;
  border-radius: 8px;
}

.blog-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-text {
  text-align: center;
}

.blog-title {
  font-size: 1.5em;
  margin: 0;
  color: #333;
}

.blog-excerpt {
  color: #666;
  font-size: 1em;
  margin: 10px 0;
}

.read-more {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.read-more:hover {
  text-decoration: underline;
}


/* ..........................................................Login Page........................................................... */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.login-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-card h2 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group label i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
}

.form-control {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-Register {
  font-size: 30px;
  font-weight: 600;
  /* color: var(--orangecolor); */
  text-align: center;
}

.register-link {
  margin-top: 10px;
}

.register-link a {
  color: #007bff;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}

.Login-btn {
  padding: 2%;
  width: 100%;
  border: none;
  color: rgba(0, 0, 0, 0.703);
  font-weight: 600;
  background-color: rgba(218, 165, 32, 0.675);
  border-radius: 10px;
}

.Login-btn:hover {
  color: white;
  background-color: var(--orangecolor);
}


/* ..........................................................Contact Us........................................................... */
.contact-page {
  margin: 8% 15%;
}

.contact-form,
.contact-info {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 12px rgba(126, 124, 124, 0.315);
}

.contact-form label {
  font-weight: 500;
  color: #666;
}

.contact-form h2 {
  color: rgba(0, 0, 0, 0.782);
  font-weight: 700;
  font-size: 25px;
}

.contact-form {
  width: 70%;
  margin-right: 20px;
}

.contact-form h4 {
  color: var(--orangecolor);
  font-style: bold;
  font-size: 18px;
}

.contact-info {
  width: 30%;
  text-align: start;
}

.contact-info h2 {
  color: var(--orangecolor);
}

.contact-info h5 {
  margin-top: 10px;
  color: rgb(0, 0, 0);
}

.contact-form h2 {
  margin-bottom: 20px;
}

.contact-form input{
  border: 1px solid rgba(128, 128, 128, 0.142);
  border-radius: 0;
}

.contact-form textarea{
  border: 1px solid rgba(128, 128, 128, 0.142);
  border-radius: 0;
}

.contact-info p {
  margin: 10px 0;
}

.contact-info i {
  margin-right: 8px;
  font-size: 15px;
  color: white;
  background-color: rgb(255, 183, 0);
  margin-top: 5%;
  padding: 10px 10px;
  border-radius: 5px;
}

.contact-page span {
  color: red;
}

.contact-btn {
  background-color: black;
  color: white;
  font-weight: 600;
  padding: 1.5% 5%;
  border: none;
}

.contact-btn:hover {
  background-color: var(--orangecolor);  
  border: none;
  transition: all 0.5s ease;
}

@media (max-width: 768px) {
  .contact-page {
    margin: 20vh 5%;
  }

  .contact-form,
  .contact-info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}


/* ..........................................................Footer........................................................... */

.footer {
  padding: 50px 0;
  background-color: #000;
  color: #fff;
}

.footer-logo {
  width: 200px;
  margin-bottom: 20px;
}

.footer-description {
  font-size: 14px;
  line-height: 1.6;
}

.footer h5 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s, transform 0.2s;
}

.footer ul li a:hover {
  color: var(--orangecolor);
  text-decoration: none;
  transform: scale(1);
}

.footer address {
  font-style: normal;
  line-height: 1.6;
}

.social-icons a {
  color: #fff;
  margin-right: 10px;
  font-size: 20px;
  transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
  color: var(--orangecolor);
  transform: scale(1.1);
}

.copyright {
  margin-top: 20px;
  text-align: center;
}

.copyright p a {
  text-decoration: none;
  color: white;
}

.footer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.footer-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer-modal-header,
.footer-modal-footer {
  position: relative;
}

.footer-modal-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.footer-modal-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.footer-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.footer-modal-body {
  margin: 20px 0;
  overflow-y: auto;
  max-height: calc(100% - 120px); /* Adjust according to header and footer height */
}

.footer-modal-footer button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.footer-modal-footer .accept-button {
  background-color: #28a745;
  color: #fff;
}

.footer-modal-footer .reject-button {
  background-color: #dc3545;
  color: #fff;
}

.footer-modal-body {
  margin: 20px 0;
  overflow-y: auto;
  max-height: calc(100% - 120px); 
  
  
  ::-webkit-scrollbar {
    width: 12px; 
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 6px; 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1;
}

@media (max-width: 768px) {
  .footer .col-sm-6 {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .footer {
    text-align: center;
  }

  .footer .col-sm-6 {
    margin-bottom: 20px;
  }
}

/* ..........................................................Cart........................................................... */

.cart-container {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0;
}

.table thead th {
  background-color: var(--orangecolor);
  color: white;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 550 !important;
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
  border: none;
}

.table tbody td {
  vertical-align: middle;
  text-align: center;
  padding: 15px;
  white-space: nowrap;
  border: none;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e7e7e7;
}

.image-section {
  width: 100px;
  background-color: #e6e6e6;
}

.summary-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;

}

.coupon-section,
.cart-total {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.coupon-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-field {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

.apply-btn {
  padding: 10px 20px;
  border: none;
  background-color: white;
  font-style: bold;
  color: black;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.apply-btn:hover {
  color: var(--orangecolor);
}

.cart-total {
  width: 100%;
  padding: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product-divider {
  margin: 20px 0;
}

.btn-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
}

.shop-now-cart{
  padding: 10px 20px;
  margin: 5px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.5s ease;

}

.shop-now-cart:hover {
  background-color: var(--orangecolor);
}

.quantity-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #ddd;
  overflow: hidden;
  width: 100px;
}

.quantity-container input[type="number"] {
  width: 50px;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
}

.quantity-container button {
  border: none;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  background-color: transparent;
}

.quantity-container .btn-decrease,
.quantity-container .btn-increase {
  border: none;
}

.btn-delete{
  border: none;
  outline: none;
}

@media (max-width: 576px) {

  .table thead th,
  .table tbody td {
    padding: 10px;
  }

  .quantity-container input[type="number"] {
    width: 30px;
  }

  .summary-row {
    flex-direction: column;
  }

  .coupon-section,
  .cart-total {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .btn-div {
    width: 100%;
  }
}

/* ..........................................................Product Details........................................................... */

/* ProductsPage.css */
.products-page {
  padding: 20px;
  margin: 100px;
}

.category-dropdown {
  margin-bottom: 20px;
  text-align: center;
}

.category-dropdown label {
  font-size: 1.2em;
  margin-right: 10px;
}

.category-dropdown select {
  font-size: 1em;
  padding: 5px;
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s;
}

.product:hover {
  transform: scale(1.02);
}

.product-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.product-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.product-name {
  font-size: 1.5em;
  margin: 0;
}

.product-description {
  color: #666;
  font-size: 0.9em;
}

.product-btns {
  display: flex;
  justify-content: end;
  margin-top: 15px;

}

.add-to-cart-btn,
.shop-now-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart-btn {
  font-size: 1.5em;
  color: #000000;
  cursor: pointer;
  transition: color 0.3s;
}

.add-to-cart-btn:hover {
  color: var(--orangecolor);
}

.shop-now-btn {
  background-color: #007bff;
  color: #fff;
}

.product-rating{
  width: 100px;
  height: 100%;
  margin-left: -1%;
  margin-bottom: 1%;
  background: transparent;
}

.productPrice{
  color: brown;
}

.wishlist-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.wishlist-icon-image {
  width: 24px;
  height: 24px;
}


/* ..........................................................WishList........................................................... */

.wishlist-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 100px;
}

.wishlist-content {
  flex: 1;
  padding: 20px;
  margin: 20px;
}

.wishlist-header {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.wishlist-table {
  width: 100%;
  border-collapse: collapse;
}

.wishlist-table th,
.wishlist-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.wishlist-product-image {
  width: 100px;
  height: auto;
}

.quantity-control {
  display: flex;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.116);
  width: fit-content;
}

.btn-decrement,
.btn-increment {
  color: black;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.116);
}

input[type="number"] {
  width: 40px;
  text-align: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.171);
  margin: 0 5px;
}

.wishlist-btn-add {
  background: none;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.wishlist-btn-delete {
  background: none;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.wishlist-btn-add:hover {
  color: var(--orangecolor);
}

.wishlist-btn-delete:hover {
  color: red;
}

.wishlist-table-head{
  background-color: var(--orangecolor);
  color: white;
  font-weight: 600;
  height: 70px;
}

.no-items-message {
  text-align: center;
  font-size: 3rem;
  color: #555;
  padding: 10px;
  margin-top: 50px;
}

@media (min-width: 270px) and (max-width: 576px) {
  .wishlist-header {
    font-size: 1.5rem;
  }

  .wishlist-product-image {
    width: 60px;
  }

  .quantity-control {
    flex-direction: column;
  }

  .btn-decrement,
  .btn-increment {
    padding: 2px 4px;
  }

  input[type="number"] {
    width: 25px;
  }

  .wishlist-btn {
    font-size: 1rem;
    padding: 4px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .wishlist-header {
    font-size: 1.5rem;
  }

  .wishlist-product-image {
    width: 80px;
  }

  .quantity-control {
    flex-direction: row;
  }

  .btn-decrement,
  .btn-increment {
    padding: 3px 6px;
  }

  input[type="number"] {
    width: 30px;
  }

  .wishlist-btn {
    font-size: 1.2rem;
    padding: 5px;
  }
}

@media (min-width: 769px) {
  .wishlist-header {
    font-size: 2rem;
  }

  .wishlist-product-image {
    width: 100px;
  }

  .quantity-control {
    flex-direction: row;
  }

  .btn-decrement,
  .btn-increment {
    padding: 5px 10px;
  }

  input[type="number"] {
    width: 40px;
  }

  .wishlist-btn {
    font-size: 1.5rem;
    padding: 10px;
  }
}

/* .....................................................Login And Registration Page...................................................... */
.login-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  animation: fadeIn 1.5s;
}

.login-popup-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-popup-title h2 {
  margin: 0;
  font-size: 24px;
}

.login-popup-title img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.login-popup-inputs input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-popup-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #084078;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.login-popup-container button:hover {
  background-color: #084078;
}

.login-popup-condition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.login-popup-condition input {
  margin-right: 10px;
}

.login-popup-condition p {
  margin: 0;
  font-size: 14px;
}

.login-popup-container p {
  text-align: start;
  font-size: 14px;
}

.login-popup-container p span {
  color: #084078;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 1.1rem;
}

/* ..........................................................CheckOut Page........................................................... */
.checkout-container {
  margin-top: 120px;
  height: 90vh;
}

.checkout-container h2 {
  font-weight: bold;
}

.checkout-form{
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  padding: 2%;
  /* border-radius: 10px; */
}

.checkout-payment{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.11);
    padding: 2%;
    border-radius: 10px;
}

.checkout-form label{
  color: rgb(96, 95, 95);
  font-weight: 500;
}

.checkout-form input{
  /* border: none; */
  border-radius: 0;
}

.checkout-form select{
  border-radius: 0;
}

.checkout-form input::placeholder {
  color: #888;
  font-size: 12px;
}

.checkout-card {
  margin-top: 30px;
}

.checkout-card-title {
  font-weight: bold;
  font-size: 15px;
}

.checkout-list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-form-check-label {
  margin-left: 10px;
}

.checkout-btn-block {
  margin-top: 20px;
  padding: 1.5% 5%;
  border: none;
  background-color: #222222;
  color: white;
  }

.checkout-btn-block:hover {
  background-color: var(--orangecolor);
  color: white;
  transition: all 0.5s ease;
}

.image-row {
  gap: 20px; 
  width: fit-content;
}

.image-card {
  width: 80px;
  max-width: 100px; 
  height: 40px; 
  flex: 1; 
  object-fit: contain; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11);

}

.ul-head {
  position: relative;
}

.ul-head::before {
  content: "";
  position: absolute;
  /* left: 48%; */
  bottom: 0; 
  width: 60px;
  height: 1px; 
  background-color: var(--orangecolor); 
}

/* -------------- Mobile Screen ---------- */

@media (max-width: 768px) {
  
.home-container {
  display:block;
  margin-top: 100px;
  padding-top: 10px;
}

.text-content {
  flex: 1;
  max-width: 100%;
  margin-right: 20px; 
  padding-left: 25%;
}

.discount {
  font-size: 1rem;
}

.main-heading {
  font-size: 2rem;
}

.description {
  font-size: 1rem;;
  width: 100%;
}

.shop-now {
  display: none;
}

/* ..........................................................Home Carousel........................................................... */

.product-heading {
  margin-bottom: -100px;
}

.show-btn{
  display: none;
}

/*  ..........................................................Services........................................................... */

.services-section {
  padding: 10px;
}

.services-heading {
  font-size: 1rem !important;
  color: #333;
  margin-bottom: 20px;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 0px;
  margin: auto 0px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-left: -10px !important;
  border-radius: 10px;
  width: 200px;
}

.service-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 10px;
}

.s-heading{
  margin-bottom: 0px;  
}

.service-heading {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.service-description {
  font-size: 1rem;
  color: #666;
}

.blog-head{
  margin: 0;
}

.products {
  display: block;
  width: 250px;
  height: fit-content;
  margin: 10px 0;
  margin-left: -40px;
}

.product{
  margin: 30px 0;
}

/* ..........................................................Cart........................................................... */

.cart-container {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.cart-table {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 500px; /* Adjust the height as needed */
  border: 1px solid #ddd; /* Optional border */
}

.table {
  width: 1000px; /* Adjust as needed */
  border-collapse: collapse; /* Remove spacing between cells */
}

.table th,
.table td {
  padding: 8px 12px; /* Adjust padding as needed */
  text-align: left; /* Align text to the left */
  border-bottom: 1px solid #ddd; /* Add border to cells */
}

.table thead {
  position: sticky; /* Make header sticky */
  top: 0;
  background: #fff; /* Background color for the sticky header */
  z-index: 1; /* Ensure header is above other content */
}

/* ..........................................................CheckOut Page........................................................... */

.checkout-container {
  margin-top: 120px;
  height: fit-content;
}

/* ..........................................................WishList........................................................... */

.wishlist-page {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.wishlist-content {
  flex: 1;
  padding: 0px;
  margin: 20px;
}

.wishlist-header {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.wishlist-table {
  width: 100%;
  overflow-x: auto !important; /* Enable horizontal scrolling if needed */
  overflow-y: auto !important; /* Enable vertical scrolling */
  max-height: 500px !important; /* Adjust the height as needed */
  border: 1px solid #ddd !important; /* Optional border */
}

.wishlist-table th,
.wishlist-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.wishlist-product-image {
  width: 100px;
  height: auto;
}
}

.AddtoProductmodal{
  margin: auto;
  position: fixed ;
  left:0%;
  top: 50%;
  right: 10px;
  padding: 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 1000;
  width:fit-content;
}
/* 
.modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  background-color: #4caf50;
  color: rgb(184, 27, 27);
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 0;
} */
